<template>
  <form id="login-form" class="form" @submit.prevent="handleSubmit">
    <div class="log_main">
      <div class="bindPhine_title">
        <p>已向手机号{{ phoneMask }}发送短信验证码，请输入</p>
      </div>
      <InputSms :phone="phone" :isCounting="true" />
      <InputCaptcha />
      <div class="append_op dsib">
        <div class="dsib autoLogSet" id="err">{{ errmsg }}</div>
      </div>
      <div class="logBtnOuter">
        <BtnWithLoading type="submit" class="btn">下一步</BtnWithLoading>
      </div>
    </div>
  </form>
</template>
<script>
import mapState from '@/mixins/map-state'
import updateStore from '@/mixins/update-store'
import handleErr from '@/mixins/handle-err'

export default {
  mixins: [mapState, updateStore, handleErr],
  data() {
    return {
      isPending: false
    }
  },
  mounted() {
    if (!this.account || !this.phoneMask) {
      this.$router.replace('/login')
    }
  },
  methods: {
    handleSubmit() {
      let payload = {
        _csrf: this.csrf,
        challenge: this.challenge,
        LoginType: this.loginType
      }

      payload['accountName'] =
        this.account && this.encrypter.encrypt(this.account)
      payload['sms_code'] = this.sms && this.encrypter.encrypt(this.sms)
      payload['phoneCipher'] = this.phoneCipher

      this.isPending = true
      this.$api
        .verifysms(payload)
        .then(res => {
          this.isPending = false
          console.log(res)
          res.data = res.data || {}
          if (res.data.errcode !== '0') {
            this.updateStore('errmsg', res.data.errmsg)
          }

          if (res.data.errcode === '0') {
            this.updateStore('errmsg', '')

            if (res.data.data.ResetPwdCode) {
              this.updateStore('resetPwdCode', res.data.data.ResetPwdCode)
              this.$router.push('/resetpwd')
            }
          }
        })
        .catch(err => {
          this.handleErr(err)
        })
    }
  }
}
</script>
